// Absolute paths are required by parcelJS
import { HeaderLinkProps } from '@royalacademy/component-library';
import transformEventCard from '../components/Header/transform';
import { FeaturedItem } from 'types/featuredItem';
import getFilteredFeaturedItems from '../utils/getFilteredFeaturedItems';

export const headerNavigationLinks = (data: FeaturedItem[] | null): HeaderLinkProps[] => {
  const whatsOnItem = getFilteredFeaturedItems(data, 'exhibition/');
  const whatsOnCard: FeaturedItem = whatsOnItem != null ? Object(whatsOnItem[0]) : null;

  const visitItem = getFilteredFeaturedItems(data, 'page/plan-your-visit');
  const visitCard: FeaturedItem = visitItem != null ? Object(visitItem[0]) : null;

  const learnItem = getFilteredFeaturedItems(data, 'event/');
  const learnCard: FeaturedItem = learnItem != null ? Object(learnItem[0]) : null;

  const artAndArtistsItem = getFilteredFeaturedItems(data, 'art-artists/');
  const artAndArtistsCard: FeaturedItem = artAndArtistsItem != null ? Object(artAndArtistsItem[0]) : null;

  const supportItem = getFilteredFeaturedItems(data, 'page/support-us');
  const supportCard: FeaturedItem = supportItem != null ? Object(supportItem[0]) : null;

  const headerLinkProps: HeaderLinkProps[] = [
    {
      label: "What's on",
      slug: 'whats-on',
      baseUrl: 'website',
      subNavColor: 'theme--ra-light-blue',
      subNav: [
        {
          baseUrl: 'website',
          label: 'See all',
          slug: 'exhibitions-and-events',
        },
        {
          baseUrl: 'website',
          label: 'Exhibitions',
          slug: 'exhibitions-and-events?page=1&what-filter=free-exhibitions-and-displays%2Cexhibitions%2Cfeatured-selling-displays',
        },
        {
          baseUrl: 'website',
          label: 'Events',
          slug: 'exhibitions-and-events?what-filter=talks-lectures%2Cshort-courses%2Cworkshops%2Cperformance-film%2Clates-festivals%2Ctours',
        },
        {
          baseUrl: 'website',
          label: 'Free displays',
          slug: 'exhibitions-and-events?page=1&what-filter=free-exhibitions-and-displays%2Cfeatured-selling-displays',
        },
      ],
      card: transformEventCard(whatsOnCard),
    },
    {
      label: 'Visit',
      slug: 'visit',
      baseUrl: 'website',
      subNavColor: 'theme--ra-light-green',
      subNav: [
        {
          baseUrl: 'website',
          label: 'Plan your visit',
          slug: 'plan-your-visit',
        },
        {
          baseUrl: 'website',
          label: 'Eating & drinking',
          slug: 'eating-and-drinking',
        },
        {
          baseUrl: 'website',
          label: 'Group visits',
          slug: 'groups',
        },
        {
          baseUrl: 'website',
          label: 'Access & facilities',
          slug: 'page/access-at-the-ra',
        },
      ],
      card: transformEventCard(visitCard),
    },
    {
      label: 'Learn',
      slug: 'learn',
      baseUrl: 'website',
      subNavColor: 'theme--ra-light-pink',
      subNav: [
        {
          baseUrl: 'website',
          label: 'Talks and lectures',
          slug: 'exhibitions-and-events?what-filter=talks-lectures',
        },
        {
          baseUrl: 'website',
          label: 'Courses and classes',
          slug: 'exhibitions-and-events?what-filter=short-courses',
        },
        {
          baseUrl: 'website',
          label: 'Teachers & schools',
          slug: 'teachers-and-schools',
        },
        {
          baseUrl: 'website',
          label: 'Families',
          slug: 'families',
        },
        {
          baseUrl: 'website',
          label: 'RA Schools Postgraduate Programme',
          slug: 'the-ra-schools',
        },
        {
          baseUrl: 'website',
          label: 'Executive MA in Cultural Leadership',
          slug: 'page/executive-master-cultural-leadership',
        },
      ],
      card: transformEventCard(learnCard),
    },
    {
      label: 'Art & Architecture',
      slug: 'art-and-architecture',
      baseUrl: 'website',
      subNavColor: 'theme--ra-light-grey',
      subNav: [
        {
          baseUrl: 'website',
          label: 'RA Art & Artists',
          slug: 'art-artists',
        },
        {
          baseUrl: 'website',
          label: 'RA Collection',
          slug: 'art-artists/search/search-the-collection',
        },
        {
          baseUrl: 'website',
          label: 'Royal Academicians',
          slug: 'royal-academicians',
        },
        {
          baseUrl: 'website',
          label: 'RA Schools students and graduates',
          slug: 'the-ra-schools/graduates-of-the-ra-schools',
        },
        {
          baseUrl: 'website',
          label: 'Architecture programme',
          slug: 'page/architecture',
        },
        {
          baseUrl: 'website',
          label: 'Previous exhibitions',
          slug: 'past-exhibitions',
        },
        {
          baseUrl: 'se',
          label: 'Summer Exhibition Explorer',
          slug: '',
        },
        {
          baseUrl: 'yass',
          label: 'Young Artists’ Summer Show',
          slug: '?_ga=2.223981977.383572690.1657014036-1195714866.1653487039&_gl=1*nmt0ik*_ga*MTE5NTcxNDg2Ni4xNjUzNDg3MDM5*_ga_4VG7PSR0K4*MTY1NzAxOTYyOC4xMDkuMS4xNjU3MDE5OTMxLjA.',
        },
      ],
      card: transformEventCard(artAndArtistsCard),
    },
    {
      label: 'Join & Support',
      slug: 'join-and-support',
      subNavColor: 'theme--ra-light-purple',
      baseUrl: 'website',
      subNav: [
        {
          baseUrl: 'website',
          label: 'Discover memberships',
          slug: 'page/memberships',
        },
        {
          baseUrl: 'website',
          label: 'Become a Friend',
          slug: 'page/friends-of-the-royal-academy',
        },
        {
          baseUrl: 'website',
          label: 'Become a Patron',
          slug: 'page/patrons',
        },
        {
          baseUrl: 'website',
          label: 'Corporate',
          slug: 'page/corporate-support',
        },
        {
          baseUrl: 'website',
          label: 'Philanthropy',
          slug: 'page/support-us',
        },
      ],
      card: transformEventCard(supportCard),
    },
  ];

  return headerLinkProps;
};
