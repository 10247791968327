import React, { ReactElement, useState, useCallback, useMemo, useEffect } from 'react';
import './explorer-filter.scss';
import { useMobile } from '../../../hooks/mediaHook';
import { Checkbox } from '../../FormFields/FormFields';
import Cta from '../../Cta/Cta';
import Tag from '../../Tag/Tag';
import ExplorerFilterDropdown from './ExplorerFilterDropdown/ExplorerFilterDropdown';
import { handleArtworkExplorerGADataLayerPush } from '../../../utils/filterFormat';
import { breakpoints } from '../../../util';
import { FilterProps, FilterType } from '../ExplorerArtworks';
import Icon from '../../Icons/Icons';

export type ExplorerFilterDropdownProps = {
  label: string;
  checkboxes: FilterProps[];
};

export type ExplorerFilterProps = {
  onFilterChange: (filters: FilterProps[], availableToBuyOnline: boolean) => void;
  currentFilters: FilterProps[];
  availableToBuyOnline: boolean;
  initialUrlParams?: {
    category?: string;
    price?: string;
    size?: string;
  };
};

export default function ExplorerFilter({
  onFilterChange,
  currentFilters,
  availableToBuyOnline,
  initialUrlParams
}: ExplorerFilterProps): ReactElement {
  const mobile = useMobile(breakpoints.md, true);
  const theme = 'theme--ra-black';
  const [dropdownOpen, setDropdownValue] = useState('');
  const [mobileFiltersVisible, setMobileFiltersVisible] = useState(false);
  const [tempFilters, setTempFilters] = useState(currentFilters);
  const [tempAvailableToBuyOnline, setTempAvailableToBuyOnline] = useState(availableToBuyOnline);
  const [filtersChanged, setFiltersChanged] = useState(false);

  useEffect(() => {
    setTempFilters(currentFilters);
    setTempAvailableToBuyOnline(availableToBuyOnline);
    setFiltersChanged(false);
  }, [currentFilters, availableToBuyOnline, mobileFiltersVisible]);

  const toggleMobileFilters = useCallback(() => {
    setMobileFiltersVisible((prev) => !prev);
  }, []);

  const onCheckboxClick = useCallback(
    (n: string, v: string, t: FilterType): void => {
      setTempFilters((prevFilters) => {
        const i = prevFilters.findIndex((f) => f.value === v);
        const newFilters =
          i === -1 ? [...prevFilters, { label: n, value: v, type: t }] : prevFilters.filter((f) => f.value !== v);

        if (!mobile) {
          // Immediately apply filters on desktop
          handleArtworkExplorerGADataLayerPush(t, v);
          onFilterChange(newFilters, tempAvailableToBuyOnline);
        } else {
          setFiltersChanged(true);
        }

        return newFilters;
      });
    },
    [mobile, onFilterChange, tempAvailableToBuyOnline]
  );

  const handleAvailableOnlineChange = useCallback(() => {
    const newValue = !tempAvailableToBuyOnline;
    handleArtworkExplorerGADataLayerPush('available online', newValue ? 'true' : 'false');
    setTempAvailableToBuyOnline(newValue);

    if (!mobile) {
      // Immediately apply filter on desktop
      onFilterChange(tempFilters, newValue);
    } else {
      setFiltersChanged(true);
    }
  }, [mobile, onFilterChange, tempFilters, tempAvailableToBuyOnline]);

  const applyFilters = useCallback(() => {
    onFilterChange(tempFilters, tempAvailableToBuyOnline);
    setMobileFiltersVisible(false);
    setFiltersChanged(false);
  }, [onFilterChange, tempFilters, tempAvailableToBuyOnline]);

  const clearAllFilters = useCallback((): void => {
    handleArtworkExplorerGADataLayerPush('clear filters', currentFilters.map((filter) => filter.label).join(','));
    onFilterChange([], false);
    setTempFilters([]);
    setTempAvailableToBuyOnline(false);
    setFiltersChanged(false);
  }, [currentFilters, onFilterChange]);

  const removeFilter = useCallback(
    (label: string): void => {
      setTempFilters((prevFilters) => {
        const newFilters = prevFilters.filter((f: FilterProps) => f.label !== label);

        if (!mobile) {
          // Immediately apply filters on desktop
          onFilterChange(newFilters, tempAvailableToBuyOnline);
        } else {
          setFiltersChanged(true);
        }

        return newFilters;
      });
    },
    [mobile, onFilterChange, tempAvailableToBuyOnline]
  );

  const dropdownClickHandler = useCallback((dropdownName: string): void => {
    setDropdownValue((prevOpen) => (prevOpen === dropdownName ? '' : dropdownName));
  }, []);

  const closeDropdown = useCallback(
    (dropdownName: string) => {
      if (dropdownOpen === dropdownName && !mobile) {
        setDropdownValue('');
      }
    },
    [dropdownOpen, mobile]
  );

  const dropdowns: ExplorerFilterDropdownProps[] = useMemo(
    () => [
      {
        label: 'Category',
        checkboxes: [
          { label: 'Print', value: 'PRINT', type: 'Category' },
          { label: 'Painting', value: 'PAINTING', type: 'Category' },
          { label: 'Architecture', value: 'ARCHITECTURE', type: 'Category' },
          { label: 'Drawing', value: 'DRAWING', type: 'Category' },
          { label: 'Film', value: 'FILM', type: 'Category' },
          { label: 'Photography', value: 'PHOTOGRAPH', type: 'Category' },
          { label: 'Sculpture', value: 'SCULPTURE', type: 'Category' }
        ]
      },
      {
        label: 'Price',
        checkboxes: [
          { label: 'Under £250', value: 'under-250', type: 'Price' },
          { label: '£250–£500', value: '250-500', type: 'Price' },
          { label: '£500–£1,000', value: '500-1000', type: 'Price' },
          { label: '£1,000–£2,000', value: '1000-2000', type: 'Price' }
        ]
      },
      {
        label: 'Size',
        checkboxes: [
          { label: 'Small', value: 'S', type: 'Size' },
          { label: 'Medium', value: 'M', type: 'Size' },
          { label: 'Large', value: 'L', type: 'Size' },
          { label: 'Extra Large', value: 'XL', type: 'Size' }
        ]
      }
    ],
    []
  );

  useEffect(() => {
    if (initialUrlParams) {
      const newFilters: FilterProps[] = [];

      Object.entries(initialUrlParams).forEach(([paramKey, paramValue]) => {
        if (paramValue) {
          const dropdown = dropdowns.find((d) => d.label.toLowerCase() === paramKey);

          if (dropdown) {
            const matchingCheckbox = dropdown.checkboxes.find(
              (c) => c.value.toLowerCase() === paramValue.toLowerCase()
            );

            if (matchingCheckbox) {
              newFilters.push({
                label: matchingCheckbox.label,
                value: matchingCheckbox.value,
                type: dropdown.label as FilterType
              });

              setDropdownValue(dropdown.label);
            }
          }
        }
      });

      if (newFilters.length > 0) {
        setTempFilters(newFilters);
        onFilterChange(newFilters, tempAvailableToBuyOnline);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialUrlParams]);

  return (
    <div className={`explorer-filters ${theme}`}>
      <div className="explorer-filters__container">
        <div className="explorer-filters__grid">
          <h1 className="explorer-filters__title">
            <em>Artworks</em>
          </h1>
          {mobile && (
            <button className="explorer-filters__mobile-button" onClick={toggleMobileFilters}>
              <span className="explorer-filters__mobile-button-content">
                <span className="explorer-filters__mobile-button-text">Filters</span>
                <Icon icon="sliders" />
              </span>
            </button>
          )}
          {mobile && !mobileFiltersVisible && (currentFilters.length > 0 || availableToBuyOnline) && (
            <div className="explorer-filters__applied-filters">
              {currentFilters.map((filter) => (
                <Tag
                  key={`applied-filter-${filter.label}`}
                  label={`${filter.type}: ${filter.label}`}
                  close={true}
                  onClick={() => {
                    const newFilters = currentFilters.filter((f) => f.label !== filter.label);
                    onFilterChange(newFilters, availableToBuyOnline);
                  }}
                />
              ))}
              {availableToBuyOnline && (
                <Tag
                  key="applied-filter-available-online"
                  label="Available to buy online"
                  close={true}
                  onClick={() => onFilterChange(currentFilters, false)}
                />
              )}
              <Cta type="tertiary" label="Clear all filters" hideArrow={true} onClick={clearAllFilters} theme={theme} />
            </div>
          )}
          <div
            className={`explorer-filters__content ${
              mobile && !mobileFiltersVisible ? 'explorer-filters__content--hidden' : ''
            }`}
          >
            <div className="explorer-filters__checkboxes">
              <Checkbox
                type="checkbox"
                name="Available to buy online"
                label="Available to buy online"
                value="available-online"
                id="available-online-checkbox"
                checked={tempAvailableToBuyOnline}
                onChange={handleAvailableOnlineChange}
                theme={theme}
              />
            </div>
            <div className="explorer-filters__filters theme--ra-white">
              {dropdowns.map((d, index) => (
                <ExplorerFilterDropdown
                  {...d}
                  open={dropdownOpen === d.label}
                  onClick={dropdownClickHandler}
                  key={`${d.label}--${index}`}
                  checkboxes={d.checkboxes}
                  checkboxOnClick={onCheckboxClick}
                  filters={tempFilters}
                  mobile={mobile}
                  closeDropdown={closeDropdown}
                />
              ))}
            </div>

            <div className="explorer-filters__selected-filters">
              {tempFilters.map((filter) => (
                <Tag
                  key={`filters__tag--${filter.label}`}
                  label={`${filter.type}: ${filter.label}`}
                  close={true}
                  onClick={() => removeFilter(filter.label)}
                />
              ))}
              {tempAvailableToBuyOnline && (
                <Tag
                  key="filters__tag--available-online"
                  label="Available to buy online"
                  close={true}
                  onClick={() => {
                    handleAvailableOnlineChange();
                  }}
                />
              )}
            </div>
            {(tempFilters.length > 0 || tempAvailableToBuyOnline) && (
              <div className="explorer-filters__clear-filters">
                <Cta
                  type="tertiary"
                  label="Clear all filters"
                  hideArrow={true}
                  onClick={clearAllFilters}
                  theme={theme}
                />
              </div>
            )}
          </div>
        </div>
        {mobile && mobileFiltersVisible && filtersChanged && (
          <button className="explorer-filters__apply-button" onClick={applyFilters}>
            <span>Apply filters</span>
            <Icon icon="arrow-right" />
          </button>
        )}
      </div>
    </div>
  );
}
