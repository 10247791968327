import React, { ReactElement, useEffect, useRef, useState, useCallback } from 'react';
import './explorer-filter-dropdown.scss';
import { Checkbox } from '../../../FormFields/FormFields';
import Icon from '../../../Icons/Icons';
import { FilterProps, FilterType } from '../../ExplorerArtworks';
import { useOnClickOutside } from '../../../../hooks/onClickOutside';

export type ExplorerFilterDropdownProps = {
  label: string;
  open?: boolean;
  onClick: (s: string) => void;
  checkboxes: FilterProps[];
  checkboxOnClick: (label: string, value: string, type: FilterType) => void;
  filters: FilterProps[];
  mobile: boolean;
  closeDropdown: (name: string) => void;
};

export default function ExplorerFilterDropdown({
  label,
  open = true,
  onClick,
  checkboxes,
  checkboxOnClick,
  filters,
  mobile,
  closeDropdown
}: ExplorerFilterDropdownProps): ReactElement {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const theme = 'theme--ra-light-blue';

  const countCheckedFieldsFn = useCallback((): void => {
    const checkedCount = filters.filter((f) => checkboxes.some((c) => c.value === f.value)).length;
    setSelectedCheckboxes(checkedCount);
  }, [filters, checkboxes]);

  useEffect(() => {
    countCheckedFieldsFn();
  }, [filters, countCheckedFieldsFn]);

  const onClickOutside = useCallback(
    (e: Event) => {
      if (buttonRef.current?.contains(e.target as Node)) return;
      closeDropdown(label);
    },
    [label, closeDropdown]
  );

  useOnClickOutside(contentRef, onClickOutside);

  return (
    <div
      className={`explorer-filter-dropdown ${open ? 'explorer-filter-dropdown--active' : ''} ${
        mobile ? 'explorer-filter-dropdown--mobile' : ''
      }`}
      ref={ref}
    >
      <button className="explorer-filter-dropdown__title" onClick={() => onClick(label)} ref={buttonRef}>
        {label}
        {open ? <Icon icon="chevron-up"></Icon> : <Icon icon="chevron-down"></Icon>}
      </button>
      {selectedCheckboxes > 0 && <div className="explorer-filter-dropdown__selected-count">{selectedCheckboxes}</div>}
      {open && (
        <div className={'explorer-filter-dropdown__content explorer-filter-dropdown__content--show'} ref={contentRef}>
          <div className="explorer-filter-dropdown__checkboxes">
            {checkboxes.map((c, index) => (
              <Checkbox
                type="checkbox"
                name={c.label}
                label={c.label}
                value={c.value}
                id={`${label}-${c.value}`}
                checked={!!filters.find((f: FilterProps) => f.value === c.value)}
                onChange={() => {
                  checkboxOnClick(c.label, c.value, label as FilterType);
                }}
                key={`${label}__checkbox--${index}`}
                theme={theme}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
