import React, { ChangeEvent, ReactElement } from 'react';
import PersonalInfoFields from '../PersonalInfoFields/PersonalInfoFields';
import AddressFields from '../AddressFields/AddressFields';
import { AddressFormData, AddressState } from 'hooks/useAddressForm';
import AddressSearchSection from '../AddressSearch/AddressSearch';
import AddressDropdown from '../AddressDropdown/AddressDropdown';

interface AddressFormProps {
  formData: AddressFormData;
  addressState: AddressState;
  titleOptions: Array<{ value: string; label: string }>;
  onFormChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onAddressChange: (newAddress: string[]) => void;
  onFindAddress: (e: React.MouseEvent) => Promise<void>;
  onShowAddressFields: () => void;
  idPrefix: string;
  showValidation?: boolean;
  validationErrors?: { [key: string]: string };
}

export default function AddressForm({
  formData,
  addressState,
  titleOptions,
  onFormChange,
  onAddressChange,
  onFindAddress,
  onShowAddressFields,
  idPrefix,
  showValidation = false,
  validationErrors = {}
}: AddressFormProps): ReactElement {
  return (
    <div className="address-form">
      <PersonalInfoFields
        formData={formData}
        titleOptions={titleOptions}
        onFormChange={onFormChange}
        idPrefix={idPrefix}
        showValidation={showValidation}
        validationErrors={validationErrors}
      />
      <AddressSearchSection
        formData={formData}
        addressState={addressState}
        onFormChange={onFormChange}
        onFindAddress={onFindAddress}
        onShowAddressFields={onShowAddressFields}
        idPrefix={idPrefix}
        showValidation={showValidation}
        validationErrors={validationErrors}
      />
      {addressState.showDropDown && (
        <AddressDropdown
          addressOptions={addressState.addressOptions}
          onAddressSelect={(address) => onAddressChange(address)}
          idPrefix={idPrefix}
        />
      )}
      {addressState.showAddressFields && (
        <AddressFields
          selectedAddress={addressState.selectedAddress}
          onAddressChange={onAddressChange}
          idPrefix={idPrefix}
          showValidation={showValidation}
          validationErrors={validationErrors}
        />
      )}
    </div>
  );
}
