import React, { ReactElement } from 'react';
import { Input } from '../FormFields/FormFields';
import Cta from '../Cta/Cta';
import { AddressFormData, AddressState } from '../../hooks/useAddressForm';

interface AddressSearchSectionProps {
  formData: AddressFormData;
  addressState: AddressState;
  onFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFindAddress: (e: React.MouseEvent) => Promise<void>;
  onShowAddressFields: () => void;
  idPrefix: string;
  showValidation?: boolean;
  validationErrors?: { [key: string]: string };
}

export default function AddressSearchSection({
  formData,
  addressState,
  onFormChange,
  onFindAddress,
  onShowAddressFields,
  idPrefix,
  showValidation = false,
  validationErrors = {}
}: AddressSearchSectionProps): ReactElement {
  const handleFindAddress = (e: React.MouseEvent): void => {
    void onFindAddress(e);
  };

  return (
    <>
      <div className="checkout__form-section">
        <Input
          id={`${idPrefix}-house-number`}
          type="text"
          label="House name or number"
          name="houseNumber"
          value={formData.houseNumber}
          onChange={onFormChange}
          required
          inlineError={showValidation ? validationErrors.houseNumber : undefined}
        />
      </div>
      <div className="checkout__form-section checkout__form-section--postcode">
        <Input
          id={`${idPrefix}-postcode`}
          type="text"
          label="Postcode"
          name="postcode"
          value={formData.postcode}
          onChange={onFormChange}
          required
          inlineError={showValidation ? validationErrors.postcode : undefined}
        />
        <div className="checkout__address-actions">
          <Cta
            onClick={handleFindAddress}
            type="tertiary"
            label={addressState.isLoading ? 'Searching...' : 'Find address'}
          />
          <button type="button" onClick={onShowAddressFields} className="checkout__link-button">
            Enter address manually
          </button>
        </div>
      </div>
    </>
  );
}
