import React from 'react';
import './explorer-popup.scss';
import Cta from '../../Cta/Cta';
import ExplorerImage, { ExplorerImageProps } from '../../ExplorerImage/ExplorerImage';

interface Item {
  catNo: string;
  title: string;
  subtitle: string;
  imageUrl: ExplorerImageProps;
}

export interface ExplorerBasketPopupProps {
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
  title: string;
  subtitle: string;
  item: Item;
}

export default function ExplorerBasketPopup({
  onConfirm,
  onCancel,
  onClose,
  title,
  subtitle,
  item
}: ExplorerBasketPopupProps): React.ReactElement {
  return (
    <div className="explorer-basket-popup">
      <h2 className="explorer-basket-popup__title">{title}</h2>
      <p className="explorer-basket-popup__description">{subtitle}</p>

      <div className="explorer-basket-popup__item">
        <div className="explorer-basket-popup__item-image">
          <ExplorerImage {...item.imageUrl} />
        </div>
        <div className="explorer-basket-popup__item-details">
          <span className="explorer-basket-popup__item-cat-no">Cat No. {item.catNo}</span>
          <h3 className="explorer-basket-popup__item-title">{item.title}</h3>
          <span className="explorer-basket-popup__item-subtitle">{item.subtitle}</span>
        </div>
      </div>

      <div className="explorer-basket-popup__buttons">
        <a href="#" className="explorer-basket-popup__cancel-link" onClick={onCancel}>
          Cancel
        </a>
        <div className="explorer-basket-popup__confirm-button">
          <Cta label="Remove" onClick={onConfirm} type="secondary" />
        </div>
      </div>
      <button className="explorer-basket-popup__close-button" onClick={onClose} aria-label="Close">
        x
      </button>
    </div>
  );
}
