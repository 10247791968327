import React from 'react';
import './explorer-basket.scss';
import ExplorerImage, { ExplorerImageProps } from '../ExplorerImage/ExplorerImage';
import Icon, { IconType } from '../Icons/Icons';
import Cta, { CtaProps } from '../Cta/Cta';
import { HostnameProps } from 'api/interface';
import formatCurrency from '../../util';
import ExplorerBasketPopup from './ExplorerPopUp/ExplorerPopUp';

export interface ExplorerBasketItem {
  artworkId: number;
  reservationId: number;
  catalogueNumber: string;
  title: string;
  artist: string;
  type: string;
  price: number;
  deposit: number;
  image: ExplorerImageProps;
  note: string;
  isExhibit: boolean;
}

export interface ExplorerBasketPageProps {
  items: ExplorerBasketItem[];
  total: number;
  deposit: number;
  cta: CtaProps;
  emptyCta: CtaProps;
  hostnames: HostnameProps;
  onDeleteItem?: (artworkId: number, reservationId: number) => Promise<void>;
  isDeleting?: number | null;
  error?: string | null;
}

export default function ExplorerBasketPage({
  items,
  total,
  deposit,
  cta,
  emptyCta,
  onDeleteItem,
  isDeleting,
  error
}: ExplorerBasketPageProps): React.ReactElement {
  const [itemToDelete, setItemToDelete] = React.useState<ExplorerBasketItem | null>(null);
  const handleDeleteClick = (item: ExplorerBasketItem): void => {
    setItemToDelete(item);
  };

  const handleConfirmDelete = (): void => {
    if (itemToDelete && onDeleteItem) {
      void onDeleteItem(itemToDelete.artworkId, itemToDelete.reservationId);
      setItemToDelete(null);
    }
  };

  const handleCancelDelete = (): void => {
    setItemToDelete(null);
  };

  if (items.length === 0) {
    return (
      <div className="basket-page__sold-out">
        <div className="basket-page__content">
          <main className="basket-page__main">
            <h1 className="basket-page__title">Basket</h1>
            <div className="basket-page__empty">
              <div className="basket-page__empty-message">
                <p>Your basket is empty</p>
              </div>
              <div className="basket-page__empty-cta">
                <Cta {...emptyCta} />
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }

  return (
    <div className="basket-page">
      <div className="basket-page__content">
        <main className="basket-page__main">
          <h3 className="basket-page__title">Basket</h3>
          {error && <div className="basket-page__error">{error}</div>}

          {items.map((item) => (
            <div key={item.artworkId} className="basket-page__item">
              <div className="basket-page__item-image">
                <ExplorerImage {...item.image} />
              </div>
              <div className="basket-page__item-details">
                <div className="basket-page__item-header">
                  <span className="basket-page__item-catalogue">Cat No. {item.catalogueNumber}</span>
                  <button
                    className="basket-page__item-remove"
                    onClick={() => handleDeleteClick(item)}
                    disabled={isDeleting === item.artworkId}
                  >
                    {isDeleting === item.artworkId ? '...' : '×'}
                  </button>
                </div>
                <h5 className="basket-page__item-artist">{item.artist}</h5>
                <p className="basket-page__item-title">{item.title}</p>
                <p className="basket-page__item-type">{item.type}</p>
                <hr className="basket-page__item-divider" />
                <div className="basket-page__item-price">
                  <span>Total price</span>
                  <span>{formatCurrency(item.price)}</span>
                </div>
                <div className="basket-page__item-deposit">
                  <span>Deposit payable today</span>
                  <span className="basket-page__item-deposit--bold">{formatCurrency(item.deposit)}</span>
                </div>
                <p className="basket-page__item-note">{item.note}</p>
              </div>
            </div>
          ))}

          <a href="/artworks" className="basket-page__continue">
            Continue shopping
          </a>
        </main>

        <aside className="basket-page__summary">
          <h4 className="basket-page__summary-title">Order summary</h4>
          <div className="basket-page__summary-total">
            <span>Order total</span>
            <span>{formatCurrency(total)}</span>
          </div>
          <div className="basket-page__summary-deposit">
            <span>Deposit payable today</span>
            <span>{formatCurrency(deposit)}</span>
          </div>
          <Cta {...cta} />
          <p className="basket-page__summary-secure">
            <Icon icon="lock" />
            Secure checkout
          </p>
          <div className="basket-page__summary-payments">
            {['visa', 'mastercard', 'maestro', 'amex'].map((card) => (
              <Icon key={card} icon={card as IconType} />
            ))}
          </div>
        </aside>
      </div>
      {itemToDelete && (
        <div className="explorer-basket-popup-overlay">
          <ExplorerBasketPopup
            title="Are you sure you want to remove this item from your basket?"
            subtitle="Please note artworks are limited and once removed from basket will be made available to other buyers"
            item={{
              catNo: itemToDelete.catalogueNumber,
              title: itemToDelete.title,
              subtitle: itemToDelete.artist,
              imageUrl: itemToDelete.image
            }}
            onConfirm={handleConfirmDelete}
            onCancel={handleCancelDelete}
            onClose={handleCancelDelete}
          />
        </div>
      )}
    </div>
  );
}
