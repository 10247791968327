import React from 'react';
import { ExplorerArtworkProps } from './ExplorerArtwork';
import formatCurrency from '../../util';

interface ArtworkOptionProps {
  label: string;
  type: string;
  price: number;
  isSelected: boolean;
  onSelect: () => void;
  availabilityText?: string;
  isSoldOut?: boolean;
}

function ArtworkOption({
  label,
  type,
  price,
  isSelected,
  onSelect,
  availabilityText,
  isSoldOut
}: ArtworkOptionProps): React.ReactElement {
  return (
    <div
      className={`artwork-option ${isSelected ? 'selected' : ''} ${isSoldOut ? 'sold-out' : ''}`}
      onClick={isSoldOut ? undefined : onSelect}
    >
      {!isSoldOut && (
        <div className="artwork-option__control">
          <input type="radio" checked={isSelected} onChange={onSelect} disabled={isSoldOut} />
        </div>
      )}
      <div className="artwork-option__content">
        <div className="artwork-option__header">
          <span className="artwork-option__label">{label}</span>
          <span className="artwork-option__price">{formatCurrency(price)}</span>
        </div>
        <span className="artwork-option__type">{type}</span>
        {isSoldOut ? (
          <div className="artwork-option__sold-tag">
            <span className="circle"></span>
            Sold
          </div>
        ) : (
          availabilityText && <span className="artwork-option__availability">{availabilityText}</span>
        )}
      </div>
    </div>
  );
}

export default function ArtworkOptions({
  isExhibitForSaleOnline,
  exhibitedPrice,
  editionPrice,
  totalOfEdition,
  numberOfEditionsAvailable,
  selectedOption,
  onOptionChange,
  numberOfExhibitedSold,
  isForSaleOnline
}: Pick<
  ExplorerArtworkProps,
  | 'isExhibitForSaleOnline'
  | 'exhibitedPrice'
  | 'editionPrice'
  | 'totalOfEdition'
  | 'numberOfEditionsAvailable'
  | 'numberOfExhibitedSold'
  | 'isForSaleOnline'
> & {
  selectedOption: 'edition' | 'exhibit';
  onOptionChange: (option: 'edition' | 'exhibit') => void;
}): React.ReactElement {
  const isEditionSoldOut = numberOfEditionsAvailable === 0;
  const isExhibitSoldOut = numberOfExhibitedSold === 1;
  return (
    <div className="artwork-options">
      {isForSaleOnline && (
        <ArtworkOption
          label="Edition"
          type="Screenprint, unframed"
          price={editionPrice}
          isSelected={selectedOption === 'edition'}
          onSelect={() => onOptionChange('edition')}
          availabilityText={isEditionSoldOut ? undefined : `${numberOfEditionsAvailable} of ${totalOfEdition} left`}
          isSoldOut={isEditionSoldOut}
        />
      )}
      {isExhibitForSaleOnline && (
        <ArtworkOption
          label="Exhibited work"
          type="Original, framed"
          price={exhibitedPrice}
          isSelected={selectedOption === 'exhibit'}
          onSelect={() => onOptionChange('exhibit')}
          isSoldOut={isExhibitSoldOut}
        />
      )}
    </div>
  );
}
