import { useState, ChangeEvent } from 'react';
import { postcodeApi } from '../api/postcodeApi';

export interface AddressFormData {
  title: string;
  firstName: string;
  lastName: string;
  phone: string;
  houseNumber: string;
  postcode: string;
}

export interface AddressState {
  addressOptions: Array<{
    label: string;
    value: number;
    rawLabel: string;
  }>;
  selectedAddress: string[];
  showDropDown: boolean;
  showAddressFields: boolean;
  isLoading: boolean;
  error: string | null;
}

export interface UseAddressFormReturn {
  formData: AddressFormData;
  addressState: AddressState;
  handleInputChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleAddressChange: (newAddress: string[]) => void;
  findAddress: (e: React.MouseEvent) => Promise<void>;
  showAddressFields: () => void;
  setFormData: React.Dispatch<React.SetStateAction<AddressFormData>>;
  setAddressState: React.Dispatch<React.SetStateAction<AddressState>>;
}

export const useAddressForm = (postcodeApiKey: string): UseAddressFormReturn => {
  const [formData, setFormData] = useState<AddressFormData>({
    title: '',
    firstName: '',
    lastName: '',
    phone: '',
    houseNumber: '',
    postcode: ''
  });

  const [addressState, setAddressState] = useState<AddressState>({
    addressOptions: [],
    selectedAddress: [],
    showDropDown: false,
    showAddressFields: false,
    isLoading: false,
    error: null
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddressChange = (newAddress: string[]): void => {
    setAddressState((prev) => ({
      ...prev,
      selectedAddress: newAddress,
      showAddressFields: true,
      showDropDown: false
    }));
  };

  const findAddress = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();
    if (!formData.postcode) return;

    setAddressState((prev) => ({ ...prev, isLoading: true, error: null }));

    try {
      const cleanPostcode = formData.postcode.replace(/ /gi, '');
      const data = await postcodeApi.get(postcodeApiKey, cleanPostcode);

      if (data.addresses) {
        const addresses = data.addresses.map((address: string, index: number) => ({
          label: address.replace(/ , ,/gi, ''),
          value: index,
          rawLabel: address
        }));

        setAddressState((prev) => ({
          ...prev,
          addressOptions: addresses,
          showDropDown: true,
          isLoading: false
        }));
      }
    } catch (error) {
      setAddressState((prev) => ({
        ...prev,
        error: "Couldn't find an address with that postcode - please try again or input manually",
        isLoading: false
      }));
    }
  };

  const showAddressFields = (): void => {
    setAddressState((prev) => ({ ...prev, showAddressFields: true }));
  };

  return {
    formData,
    addressState,
    handleInputChange,
    handleAddressChange,
    findAddress,
    showAddressFields,
    setFormData,
    setAddressState
  };
};
