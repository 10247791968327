import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import './explorer.scss';
import { useMobile } from '../../hooks/mediaHook';
import Icon from '../Icons/Icons';
import { breakpoints } from '../../util';
import { HostnameProps } from '../../api/interface';
import ExplorerExhibitionNavigation from './ExplorerExhibitionNavigation/ExplorerExhibitionNavigation';
import { explorerNavigationLinks } from '../../defaultProps';
import { BaseUrl } from '../../types';
import { SessionExplorerApiProps, SessionExplorerApi } from '../../api/sessionExplorerApi';
import { ExplorerApi, ExplorerApiProps } from '../../api/explorerApi';
import { routes } from '../../routes';
import { UseSessionStorage, SessionStorageKey } from '../../hooks/sessionStorage';

export interface ExplorerHeaderLinkProps {
  label: string;
  slug: string;
  baseUrl: BaseUrl;
  active?: boolean;
}

export interface ExplorerHeaderProps {
  hostnames: HostnameProps;
  navigationLinks: ExplorerHeaderLinkProps[];
  year: number;
}

interface UserStatusProps {
  loggedIn: boolean;
}

interface BasketStateProps {
  reservationsCount: number;
}

export default function ExplorerExhibitionHeader({
  hostnames,
  navigationLinks = explorerNavigationLinks,
  year
}: ExplorerHeaderProps): ReactElement {
  const NAV_SCROLL = 150;
  const [sticky, setSticky] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [userStatus, setUserStatus] = useState<UserStatusProps>({ loggedIn: false });
  const [basketState, setBasketState] = useState<BasketStateProps>({
    reservationsCount: 0
  });

  const mobile = useMobile(breakpoints.md, false);
  const theme = 'theme--ra-black';

  const [sessionUser, setSessionUser] = UseSessionStorage<SessionExplorerApiProps>(
    SessionStorageKey.Explorer,
    undefined
  );

  const setUserStatusFn = useCallback((user: SessionExplorerApiProps) => {
    setUserStatus({
      loggedIn: user.loggedInAsUser
    });
    if (user.loggedInAsUser) {
      document.body.classList.add('explorer-logged-in');
    }
  }, []);

  const setUserAndBasketFn = useCallback(
    (user: SessionExplorerApiProps) => {
      setUserStatusFn(user);
      setBasketState((prevState) => ({
        ...prevState,
        reservationsCount: user.reservationsCount
      }));
    },
    [setUserStatusFn]
  );

  const setSeBasketFn = useCallback((user: ExplorerApiProps) => {
    setBasketState((prevState) => ({
      ...prevState,
      reservationsCount: user.reservationsCount
    }));
  }, []);

  const isStickyFn = (): void => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= NAV_SCROLL;
    setSticky(stickyClass);
  };

  useEffect(() => {
    if (sessionUser !== undefined) setUserAndBasketFn(sessionUser);

    SessionExplorerApi.get(routes.explorer_user_url(hostnames.api))
      .then((user) => {
        setUserAndBasketFn(user);
        if (user.loggedInAsUser) {
          ExplorerApi.get(routes.explorer_user_url(hostnames.api))
            .then(setSeBasketFn)
            //eslint-disable-next-line
            .catch(console.error);
        }
        setSessionUser(user);
      })
      //eslint-disable-next-line
      .catch(console.error);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', isStickyFn);
    return () => {
      window.removeEventListener('scroll', isStickyFn);
    };
  }, []);

  const openMobileMenu = (): void => {
    setMenuOpen(true);
    document.body.classList.add('lock-scroll');
  };

  const closeMobileMenu = (): void => {
    setMenuOpen(false);
    document.body.classList.remove('lock-scroll');
  };

  const toggleMenuFn = (showMenu: boolean): ReactElement =>
    showMenu ? (
      <button onClick={closeMobileMenu}>
        <span className="sr-only">Hide menu</span>
        <Icon icon="close" />
      </button>
    ) : (
      <button onClick={openMobileMenu}>
        <span className="sr-only">Show menu</span>
        <Icon icon="menu" />
      </button>
    );

  const signInUrl = userStatus.loggedIn
    ? `${hostnames.tnew}/c/account-hub`
    : `${hostnames.tnew}/account/login?returnurl=${encodeURIComponent(String(window.location.href))}`;

  const reservationsCount = basketState.reservationsCount;

  return (
    <header
      className={`explorer-header ${theme} ${sticky ? 'explorer-header--scrolling' : ''} ${
        mobile && menuOpen ? 'explorer-header--open' : ''
      }`}
    >
      <div className={`explorer-header__container ${theme}`}>
        {mobile && (
          <div className="explorer-header__mobile">
            <div className="explorer-header__logo">
              <a href={hostnames.website}>
                <span className="sr-only">Summer Exhibition</span>
                <Icon icon="ra-logo" />
              </a>
            </div>
            <h1 className="explorer-header__title">
              <span>Summer</span>
              <span>Exhibition</span>
              <span>{year}</span>
            </h1>
            <div className="explorer-header__mobile-actions">
              <button className="explorer-header__mobile-button" aria-label="Search">
                <Icon icon="search" />
              </button>
              <a href="/basket" className="explorer-header__mobile-button" aria-label="Basket">
                <Icon icon="explorer-basket" />
                {reservationsCount !== 0 && <span className="explorer-header__basket-count">{reservationsCount}</span>}
              </a>
              <div className="explorer-header__menu-toggle">{toggleMenuFn(menuOpen)}</div>
            </div>
          </div>
        )}

        <div className="explorer-header__grid">
          {mobile && menuOpen && (
            <nav className="explorer-header__navigation">
              <ExplorerExhibitionNavigation hostnames={hostnames} links={navigationLinks} theme={theme} />
              <div className="explorer-header__mobile-nav-footer">
                <a href={signInUrl} className="explorer-header__signin-mobile">
                  {userStatus.loggedIn ? 'Sign out' : 'Sign in'}
                </a>
              </div>
              <div className="explorer-header__mobile-nav-footer">
                <a href="/basket" className="explorer-header__basket-mobile" aria-label="Basket">
                  Basket
                  {reservationsCount !== 0 && !menuOpen && (
                    <span className="explorer-header__basket-count">{reservationsCount}</span>
                  )}
                </a>
              </div>
            </nav>
          )}

          {!mobile && (
            <div className="explorer-header__top">
              <div className="explorer-header__left">
                <div className="explorer-header__logo">
                  <a href={hostnames.explorer}>
                    <span className="sr-only">Summer Exhibition</span>
                    <Icon icon="ra-logo" />
                  </a>
                </div>
                <h1 className="explorer-header__title">
                  <span>Summer</span>
                  <span>Exhibition</span>
                  <span>{year}</span>
                </h1>
                <nav className="explorer-header__navigation">
                  <ExplorerExhibitionNavigation hostnames={hostnames} links={navigationLinks} theme={theme} />
                </nav>
              </div>
              <div className="explorer-header__right">
                <div className="explorer-header__actions">
                  <div className="explorer-header__search">
                    <Icon icon="search" />
                    <input type="text" placeholder="Search by artist or catalogue no." />
                  </div>
                  <a href={signInUrl} className="explorer-header__signin">
                    {userStatus.loggedIn ? 'Sign out' : 'Sign in'}
                    <span className="explorer-header__signin-icon">
                      <Icon icon="sign-in" />
                    </span>
                  </a>
                  <a href="/basket" className="explorer-header__basket">
                    Basket
                    {reservationsCount !== 0 && (
                      <span className="explorer-header__basket-count">{reservationsCount}</span>
                    )}
                    <span className="explorer-header__basket-icon">
                      <Icon icon="explorer-basket" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}
