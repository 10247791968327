import React, { ReactElement, useState } from 'react';
import { Checkbox } from '../FormFields/FormFields';
import Cta, { CtaProps } from '../Cta/Cta';
import OrderSummary, { OrderItem } from './OrderSummary';
import AddressForm from '../AddressForm/AddressForm';
import { useAddressForm } from '../../hooks/useAddressForm';
import './checkout.scss';
import { TITLE_OPTIONS } from '../../util';
import { ThemeProps } from '../../defaultProps';

interface ValidationResult {
  isValid: boolean;
  errors: {
    [key: string]: string;
  };
}

export interface CheckoutProps {
  postcodeApiKey: string;
  cta: CtaProps;
  exhibit: boolean;
  edition: boolean;
  dispatchDate: string;
  items: OrderItem[];
  theme?: ThemeProps;
}

interface CheckoutState {
  termsAndConditions: boolean;
  termsError: string | null;
  paymentError: boolean;
  items: OrderItem[];
}

const validateAddress = (address: ReturnType<typeof useAddressForm>): ValidationResult => {
  const errors: { [key: string]: string } = {};
  const { formData, addressState } = address;

  // Validate personal info
  if (!formData.firstName) {
    errors.firstName = 'This field is required';
  }
  if (!formData.lastName) {
    errors.lastName = 'This field is required';
  }

  // Validate address based on entry method
  if (addressState.showAddressFields) {
    if (!addressState.selectedAddress[0]) {
      errors.addressLine1 = 'This field is required';
    }
    if (!addressState.selectedAddress[5]) {
      errors.city = 'This field is required';
    }
  } else {
    if (!formData.postcode) {
      errors.postcode = 'This field is required';
    }
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors
  };
};

export default function Checkout({
  postcodeApiKey,
  cta,
  exhibit,
  edition,
  dispatchDate,
  items,
  theme = 'theme--ra-light-blue'
}: CheckoutProps): ReactElement {
  const [sameAsBilling, setSameAsBilling] = useState(true);
  const [showValidation, setShowValidation] = useState(false);
  const [billingErrors, setBillingErrors] = useState<{ [key: string]: string }>({});
  const [deliveryErrors, setDeliveryErrors] = useState<{ [key: string]: string }>({});
  const [checkoutState, setCheckoutState] = useState<CheckoutState>({
    termsAndConditions: false,
    termsError: null,
    paymentError: false,
    items
  });

  const billingAddress = useAddressForm(postcodeApiKey);
  const deliveryAddress = useAddressForm(postcodeApiKey);

  const handleCtaClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowValidation(true);

    // If postcode is filled but address isn't selected yet, show address fields
    if (billingAddress.formData.postcode && !billingAddress.addressState.showAddressFields) {
      billingAddress.showAddressFields();
    }

    const billingValidation = validateAddress(billingAddress);
    const deliveryValidation = sameAsBilling ? { isValid: true, errors: {} } : validateAddress(deliveryAddress);

    setBillingErrors(billingValidation.errors);
    setDeliveryErrors(deliveryValidation.errors);

    if (!billingValidation.isValid || (!sameAsBilling && !deliveryValidation.isValid)) {
      return;
    }

    if (!checkoutState.termsAndConditions) {
      setCheckoutState((prev) => ({
        ...prev,
        termsError: 'Please accept the terms and conditions to continue'
      }));
      return;
    }

    setCheckoutState((prev) => ({
      ...prev,
      termsError: null
    }));

    if (cta.onClick) {
      cta.onClick(e);
    }
  };

  return (
    <div className="checkout">
      <div className="checkout__main">
        <header className="checkout__header">
          {checkoutState.paymentError && (
            <div className="checkout__payment-error">
              We are sorry your payment has been declined. Please check your details and try again, or use an
              alternative payment method
            </div>
          )}

          <h1 className="checkout__title">Checkout</h1>
          <div className="checkout__subtitle">Billing information</div>
        </header>

        <form className="checkout__form">
          <section className="checkout__form-section">
            <AddressForm
              formData={billingAddress.formData}
              addressState={billingAddress.addressState}
              titleOptions={TITLE_OPTIONS}
              onFormChange={billingAddress.handleInputChange}
              onAddressChange={billingAddress.handleAddressChange}
              onFindAddress={billingAddress.findAddress}
              onShowAddressFields={billingAddress.showAddressFields}
              idPrefix="billing"
              showValidation={showValidation}
              validationErrors={billingErrors}
            />
          </section>

          <section className="checkout__form-section">
            <div className="checkout__delivery-toggle">
              <Checkbox
                id="same-as-billing"
                name="sameAsBilling"
                label="Same as billing information"
                checked={sameAsBilling}
                type="checkbox"
                onChange={(e) => setSameAsBilling(e.target.checked)}
                theme="theme--ra-light-blue"
                value="same-as-billing"
              />
            </div>

            {!sameAsBilling && (
              <div className="checkout__delivery-address">
                <header className="checkout__header">
                  <h2 className="checkout__subtitle">Delivery information</h2>
                </header>

                <AddressForm
                  formData={deliveryAddress.formData}
                  addressState={deliveryAddress.addressState}
                  titleOptions={TITLE_OPTIONS}
                  onFormChange={deliveryAddress.handleInputChange}
                  onAddressChange={deliveryAddress.handleAddressChange}
                  onFindAddress={deliveryAddress.findAddress}
                  onShowAddressFields={deliveryAddress.showAddressFields}
                  idPrefix="delivery"
                  showValidation={showValidation}
                  validationErrors={deliveryErrors}
                />
              </div>
            )}
          </section>

          <footer className="checkout__form-footer">
            {edition && (
              <div className="checkout__info-exhibit">
                We’ll share your delivery address with the artist who will contact you to arrange delivery.
              </div>
            )}

            {exhibit && (
              <div className="checkout__info">
                Your order includes exhibited works. Collection required from the Royal Academy after {dispatchDate},
                when the exhibition has closed.
              </div>
            )}

            <div className="checkout__terms">
              <Checkbox
                id="terms"
                name="terms"
                type="checkbox"
                value="terms"
                theme={theme}
                label={{
                  isHtml: true,
                  content:
                    'I agree to <a href="https://www.royalacademy.org.uk/terms-and-conditions#summer-exhibition-terms-and-conditions-offer-to-purchase">Terms and Conditions</a>'
                }}
                checked={checkoutState.termsAndConditions}
                onChange={(e) =>
                  setCheckoutState((prev) => ({
                    ...prev,
                    termsAndConditions: e.target.checked,
                    termsError: null
                  }))
                }
                required
              />
            </div>

            {checkoutState.termsError && <div className="checkout__terms-error">{checkoutState.termsError}</div>}

            <div className="checkout__submit-button">
              <Cta
                label="Continue to payment"
                theme={theme}
                type="secondary"
                onClick={handleCtaClick}
                gaTags={{ type: 'cta', area: 'payment' }}
                modifier="dark"
              />
            </div>
          </footer>
        </form>
      </div>

      <div className="checkout__sidebar">
        <OrderSummary items={items} />
      </div>
    </div>
  );
}
