import React, { ChangeEvent, ReactElement } from 'react';
import { Dropdown } from '../FormFields/FormFields';
import { AddressFormData } from 'hooks/useAddressForm';
import { Input } from '../FormFields/FormFields';
interface PersonalInfoFieldsProps {
  formData: AddressFormData;
  titleOptions: Array<{ value: string; label: string }>;
  onFormChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  idPrefix: string;
  showValidation?: boolean;
  validationErrors?: { [key: string]: string };
}

export default function PersonalInfoFields({
  formData,
  titleOptions,
  onFormChange,
  idPrefix,
  showValidation,
  validationErrors = {}
}: PersonalInfoFieldsProps): ReactElement {
  return (
    <>
      <div className="form-section">
        <Dropdown
          id={`${idPrefix}-title`}
          name="title"
          label="Title"
          options={titleOptions}
          value={formData.title}
          onChange={onFormChange}
          required
          initialOption="Title"
        />
      </div>

      <div className="form-section">
        <Input
          id={`${idPrefix}-first-name`}
          type="text"
          label="First name"
          name="firstName"
          value={formData.firstName}
          onChange={onFormChange}
          required
          inlineError={showValidation ? validationErrors.firstName : undefined}
        />
      </div>
      <div className="form-section">
        <Input
          id={`${idPrefix}-last-name`}
          type="text"
          label="Last name"
          name="lastName"
          value={formData.lastName}
          onChange={onFormChange}
          required
          inlineError={showValidation ? validationErrors.lastName : undefined}
        />
      </div>

      <div className="form-section">
        <Input
          id={`${idPrefix}-phone`}
          type="tel"
          label="Phone number"
          name="phone"
          value={formData.phone}
          onChange={onFormChange}
          help="Optional"
          modifierClass="phone-input"
        />
      </div>
    </>
  );
}
