import React from 'react';
import ExplorerImage, { ExplorerImageProps } from '../ExplorerImage/ExplorerImage';
import AccordionWrapper from '../AccordionWrapper/AccordionWrapper';
import formatCurrency from '../../util';

export interface OrderItem {
  catalogNumber: string;
  artist: string;
  title: string;
  type: string;
  price: number;
  deposit: number;
  image: ExplorerImageProps;
  isExhibited?: boolean;
}

export interface OrderSummaryProps {
  items: OrderItem[];
}

export default function OrderSummary({ items }: OrderSummaryProps): React.ReactElement {
  const total = items.reduce((sum, item) => sum + item.price, 0);
  const totalDeposit = items.reduce((sum, item) => sum + item.deposit, 0);

  const renderItemContent = (item: OrderItem): string => `
      <div class="order-summary__item">
        <div class="order-summary__item-details">
          <span class="order-summary__catalog-number">Cat No. ${item.catalogNumber}</span>
          <div class="order-summary__item-title">
            <h3>${item.artist}</h3>
            <p>${item.title}</p>
          </div>
          <span class="order-summary__item-type">${item.type}</span>
          <dl class="order-summary__price-info">
            <dt>Total price</dt>
            <dd>${formatCurrency(item.price)}</dd>
            <dt>Deposit payable</dt>
            <dd>${formatCurrency(item.deposit)}</dd>
          </dl>
        </div>
      </div>
    `;

  const renderTotalSection = (): string => `
    <div class="order-summary__total-section">
      <div class="order-summary__total">
        <span>Order total</span>
        <span>${formatCurrency(total)}</span>
      </div>
      <div class="order-summary__total order-summary__total--final">
        <span>Deposit payable today</span>
        <span>${formatCurrency(totalDeposit)}</span>
      </div>
    </div>
  `;
  const accordionItems = [
    {
      body: `
        <div class="rich-text__wrapper">
          <span>
            ${items.map((item) => renderItemContent(item)).join('')}
            ${renderTotalSection()}
          </span>
        </div>
      `,
      expandText: 'View order details',
      collapseText: 'Hide order details'
    }
  ];

  return (
    <div className="order-summary">
      <div className="order-summary__desktop">
        <h2 className="order-summary__title">Order summary</h2>
        {items.map((item, index) => (
          <div key={index} className="order-summary__item">
            <div className="order-summary__item-image">
              <ExplorerImage {...item.image} />
            </div>
            <div className="order-summary__item-details">
              <span className="order-summary__catalog-number">Cat No. {item.catalogNumber}</span>
              <div className="order-summary__item-title">
                <h3>{item.artist}</h3>
                <p>{item.title}</p>
              </div>
              <span className="order-summary__item-type">{item.type}</span>
              <dl className="order-summary__price-info">
                <dt>Total price</dt>
                <dd>{formatCurrency(item.price)}</dd>
                <dt>Deposit payable</dt>
                <dd>{formatCurrency(item.deposit)}</dd>
              </dl>
            </div>
          </div>
        ))}
        <div className="order-summary__total-section">
          <div className="order-summary__total">
            <span>Order total</span>
            <span>{formatCurrency(total)}</span>
          </div>
          <div className="order-summary__total order-summary__total--final">
            <span>Deposit payable today</span>
            <span>{formatCurrency(totalDeposit)}</span>
          </div>
        </div>
      </div>
      <div className="order-summary__mobile">
        <AccordionWrapper accordionItems={accordionItems} />
      </div>
    </div>
  );
}
