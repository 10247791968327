import React, { ReactElement, useState, useCallback, useEffect } from 'react';
import './explorer-artworks.scss';
import ExplorerListing from './ExplorerListing/ExplorerListing';
import ExplorerFilter from './ExplorerFilter/ExplorerFilter';
import ArtworkNoResult from './ArtworkNoResult/ArtworkNoResult';
import { ArtworkCardProps } from '../Card/ArtworkCard/ArtworkCard';

export type FilterType = 'Category' | 'Price' | 'Size';

export type FilterProps = {
  type: FilterType;
  label: string;
  value: string;
};

export interface ExplorerArtworksProps {
  artworks: ArtworkCardProps[];
  initialPage: number;
  pageSize: number;
  onFilterChange: (filters: FilterProps[], availableToBuyOnline: boolean) => void;
  onPageChange: (page: number) => void;
  loading: boolean;
  totalCount: number;
}

export default function ArtworkExplorer({
  artworks,
  initialPage,
  pageSize,
  onFilterChange,
  onPageChange,
  loading,
  totalCount
}: ExplorerArtworksProps): ReactElement {
  const [currentFilters, setCurrentFilters] = useState<FilterProps[]>([]);
  const [availableToBuyOnline, setAvailableToBuyOnline] = useState(false);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [initialUrlParams, setInitialUrlParams] = useState<{
    category?: string;
    price?: string;
    size?: string;
  }>({});

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);
      const urlParams = {
        category: params.get('category')?.toUpperCase() || undefined,
        price: params.get('priceRange') || undefined,
        size: params.get('size')?.toUpperCase() || undefined
      };

      if (urlParams.category || urlParams.price || urlParams.size) {
        setInitialUrlParams(urlParams);
      }
    }
  }, []);

  const handleFilterChange = useCallback(
    (newFilters: FilterProps[], newAvailableToBuyOnline: boolean) => {
      setCurrentFilters(newFilters);
      setAvailableToBuyOnline(newAvailableToBuyOnline);
      setCurrentPage(1);

      if (typeof window !== 'undefined') {
        const params = new URLSearchParams();

        newFilters.forEach((filter) => {
          switch (filter.type) {
            case 'Category':
              params.set('category', filter.value.toLowerCase());
              break;
            case 'Price':
              params.set('priceRange', filter.value.toLowerCase());
              break;
            case 'Size':
              params.set('size', filter.value.toLowerCase());
              break;
          }
        });

        // Update URL without page reload
        const newUrl = `${window.location.pathname}${params.toString() ? '?' + params.toString() : ''}`;
        window.history.pushState({}, '', newUrl);
      }

      onFilterChange(newFilters, newAvailableToBuyOnline);
    },
    [onFilterChange]
  );

  const handlePageChange = useCallback(
    (newPage: number) => {
      setCurrentPage(newPage);
      onPageChange(newPage);
    },
    [onPageChange]
  );

  const clearAllFilters = useCallback(() => {
    handleFilterChange([], false);
    if (typeof window !== 'undefined') {
      window.history.pushState({}, '', window.location.pathname);
    }
  }, [handleFilterChange]);

  const renderContent = useCallback(() => {
    if (loading) return null;
    if (artworks.length === 0) return <ArtworkNoResult onReset={clearAllFilters} />;
    return (
      <ExplorerListing
        data={artworks}
        pageSize={pageSize}
        pageNumber={currentPage}
        onPageChange={handlePageChange}
        totalCount={totalCount}
        isLoading={loading}
      />
    );
  }, [loading, clearAllFilters, artworks, pageSize, currentPage, handlePageChange, totalCount]);

  return (
    <>
      <ExplorerFilter
        onFilterChange={handleFilterChange}
        currentFilters={currentFilters}
        availableToBuyOnline={availableToBuyOnline}
        initialUrlParams={initialUrlParams}
      />
      <div className="explorer-artworks">
        <section className="explorer-artworks__artworks">{renderContent()}</section>
      </div>
    </>
  );
}
