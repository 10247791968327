import React, { ReactElement, useState } from 'react';
import Cta from '../Cta/Cta';
import { Input } from '../FormFields/FormFields';
import './footer.scss';
import Icon from '../Icons/Icons';
import { useMobile } from '../../hooks/mediaHook';
import { routes } from '../../routes';
import { SignUpApi } from '../../api/signUpApi';
import { HostnameProps } from '../../api/interface';
export interface FooterLinkProps {
  label: string;
  href: string;
}

export interface FooterProps {
  about_links: FooterLinkProps[];
  policies_links: FooterLinkProps[];
  connect_links: FooterLinkProps[];
  hostnames: HostnameProps;
}

export default function Footer({ about_links, policies_links, connect_links, hostnames }: FooterProps): ReactElement {
  const [newsletterData, setNewsletterData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    interests: '',
    original_source: '',
    original_source_location: ''
  });
  const [aboutOpen, updateAboutAccordion] = useState(false);
  const [connectOpen, updateConnectOpen] = useState(false);
  const [whereOpen, updateWhereAccordion] = useState(false);
  const [signUpOpen, updateSignUpAccordion] = useState(false);
  const [utilityOpen, updateUtilityAccordion] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [apiError, setApiError] = useState(false);
  const mobile = useMobile();
  const theme = 'theme--ra-black';

  const linkOutputFn = (linkArray: Array<FooterLinkProps>, parent: string): Array<ReactElement> =>
    linkArray.map((link: FooterLinkProps, index: number) => (
      <li className={`footer__${parent}__link`} key={`${parent}-${index}`}>
        <a href={link.href} data-ga4-type="navigation" data-ga4-area="footer" data-gtm-name="nav: footer">
          {link.label}
          <Icon icon="underline" />
        </a>
      </li>
    ));

  const updateNewsletterValFn = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewsletterData((prevState) => ({ ...prevState, email: event.target.value }));
  };

  const toggleAccordionFn = (accordion: string): void => {
    switch (accordion) {
      case 'about':
        return updateAboutAccordion(!aboutOpen);
      case 'connect':
        return updateConnectOpen(!connectOpen);
      case 'utility':
        return updateUtilityAccordion(!utilityOpen);
      case 'where':
        return updateWhereAccordion(!whereOpen);
      case 'signUp':
        return updateSignUpAccordion(!signUpOpen);
    }
  };

  const hideShowArrowFn = (trigger: boolean): ReactElement =>
    trigger ? (
      <>
        <span className="sr-only"> (hide links)</span>
        <Icon icon="chevron-up" />
      </>
    ) : (
      <>
        <span className="sr-only"> (show links)</span>
        <Icon icon="chevron-down" />
      </>
    );

  const scrollTop = (): void => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onSubmitNewsletter = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    SignUpApi.create(routes.signUp(hostnames.signUp), newsletterData)
      .then(() => {
        setSignupSuccess(true);
        setApiError(false);
      })

      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        setApiError(true);
      });
  };

  const connectEl = (
    <div className="footer__context__connect">
      <h3 onClick={() => toggleAccordionFn('connect')} className={mobile ? 'accordion__handle' : ''}>
        Connect
        {mobile ? hideShowArrowFn(connectOpen) : ''}
      </h3>
      <ul className={`footer__connect__links ${connectOpen ? 'open' : ''}`}>
        <li>
          <ul className="footer__contact__links">{linkOutputFn(connect_links, 'contact')}</ul>
        </li>
        <li>
          <ul className="footer__social__links">
            <li>
              <a
                href="https://www.facebook.com/royalacademy"
                target="_blank"
                rel="noreferrer"
                className="social__facebook"
                data-ga4-type="navigation"
                data-ga4-area="footer"
                data-gtm-name="nav: footer"
              >
                <span className="social-icon__name sr-only">Facebook</span>
                <span className="footer__social-icon">
                  <Icon icon="facebook" />
                </span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/royalacademyarts/"
                target="_blank"
                rel="noreferrer"
                className="social__instagram"
                data-ga4-type="navigation"
                data-ga4-area="footer"
                data-gtm-name="nav: footer"
              >
                <span className="social-icon__name sr-only">Instagram</span>
                <span className="footer__social-icon">
                  <Icon icon="instagram" />
                </span>
              </a>
            </li>
            <li>
              <a
                href="https://www.threads.net/@royalacademyarts?igshid=NTc4MTIwNjQ2YQ=="
                target="_blank"
                rel="noreferrer"
                className="social__threads"
                data-ga4-type="navigation"
                data-ga4-area="footer"
                data-gtm-name="nav: footer"
              >
                <span className="social-icon__name sr-only">Threads</span>
                <span className="footer__social-icon">
                  <Icon icon="threads" />
                </span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );

  return (
    <footer className={`footer ${theme}`}>
      <div className="footer__container">
        <div className="footer__back-to-top">
          <Cta type="tertiary" label="Back to top" theme={theme} onClick={scrollTop} />
        </div>
        <div className="footer__context">
          <div className="footer__context__grid">
            <div className="footer__context__about">
              <h3 onClick={() => toggleAccordionFn('about')} className={mobile ? 'accordion__handle' : ''}>
                About
                {mobile ? hideShowArrowFn(aboutOpen) : ''}
              </h3>
              <ul className={`footer__about__links ${aboutOpen ? 'open' : ''}`}>
                {linkOutputFn(about_links, 'about')}
              </ul>
            </div>
            <div className="footer__context__where">
              <h3 onClick={() => toggleAccordionFn('where')} className={mobile ? 'accordion__handle' : ''}>
                How to find us
                {mobile ? hideShowArrowFn(whereOpen) : ''}
              </h3>
              <ul className={`footer__where__links ${whereOpen ? 'open' : ''}`}>
                <li className="footer__context__where__item">
                  <a
                    href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x487604d66d119133:0xf9136ba8e70e4316?source=g.page.share"
                    target="_blank"
                    rel="noreferrer"
                    data-ga4-type="navigation"
                    data-ga4-area="footer"
                    data-gtm-name="nav: footer"
                  >
                    <div className="icon icon--pin">
                      <Icon icon="location" />
                    </div>
                    <h4>Burlington House,</h4>
                    <p>Piccadilly, London, W1J 0BD</p>
                  </a>
                </li>
                <li className="footer__context__where__item">
                  <a
                    href="https://goo.gl/maps/bZy5eBX61oozefUw6"
                    target="_blank"
                    rel="noreferrer"
                    data-ga4-type="navigation"
                    data-ga4-area="footer"
                    data-gtm-name="nav: footer"
                  >
                    <div className="icon icon--pin">
                      <Icon icon="location" />
                    </div>
                    <h4>6 Burlington Gardens,</h4>
                    <p>London, W1S 3ET</p>
                  </a>
                </li>
                {<li className="footer__context__where__item">{connectEl}</li>}
              </ul>
            </div>
            {connectEl}
            <div className="footer__context__newsletter">
              <h3 onClick={() => toggleAccordionFn('signUp')} className={mobile ? 'accordion__handle' : ''}>
                Sign up to the RA Newsletter
                {mobile ? hideShowArrowFn(signUpOpen) : ''}
              </h3>
              <ul className={`footer__newsletter__links ${signUpOpen ? 'open' : ''}`}>
                <li>
                  <form onSubmit={onSubmitNewsletter}>
                    <p>
                      Find out{' '}
                      <a href="https://www.royalacademy.org.uk/privacy#how-we-may-contact-you">
                        how we may contact you
                      </a>
                    </p>
                    {apiError && (
                      <p className="footer__signup--error">
                        <span className="icon">
                          <Icon icon="exclamation" />
                        </span>
                        Sign up unsuccessful. We are currently carrying out essential maintenance. Please try again
                        later.
                      </p>
                    )}
                    {!signupSuccess && !apiError && (
                      <Input
                        required
                        id="newsletter-footer-signup"
                        type="email"
                        label="Email"
                        name="email"
                        value={newsletterData.email}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateNewsletterValFn(event)}
                      />
                    )}
                    {signupSuccess && (
                      <p className="footer__signup--success">
                        Thank you for signing up to our mailing list. We&apos;ll be in touch soon.
                      </p>
                    )}
                    {!signupSuccess && !apiError && (
                      <div className="footer__context__newsletter__cta">
                        <Cta type="primary" theme={theme} label="Sign up now" />
                      </div>
                    )}
                  </form>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer__utility">
          <div className="footer__utility__grid">
            <div className={'footer__utility__links'}>
              <h3 onClick={() => toggleAccordionFn('utility')} className={mobile ? 'accordion__handle' : ''}>
                Our policies
                {mobile ? hideShowArrowFn(utilityOpen) : ''}
              </h3>
              <ul className={utilityOpen ? 'open' : ''}>{linkOutputFn(policies_links, 'utility')}</ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
