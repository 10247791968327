import React, { ReactElement } from 'react';
import { Input } from '../FormFields/FormFields';

interface AddressFieldsProps {
  selectedAddress: string[];
  onAddressChange: (newAddress: string[]) => void;
  idPrefix: string;
  showValidation?: boolean;
  validationErrors?: { [key: string]: string };
}

export default function AddressFields({
  selectedAddress,
  onAddressChange,
  idPrefix,
  showValidation = false,
  validationErrors = {}
}: AddressFieldsProps): ReactElement {
  const handleAddressChange = (index: number, value: string): void => {
    const newAddress = [...selectedAddress];
    newAddress[index] = value;
    onAddressChange(newAddress);
  };
  return (
    <div className="address-fields">
      <div className="form-section">
        <Input
          id={`${idPrefix}-address-line-1`}
          type="text"
          label="Address line 1"
          name="addressLine1"
          value={selectedAddress[0] || ''}
          onChange={(e) => handleAddressChange(0, e.target.value)}
          required
          inlineError={
            showValidation
              ? !selectedAddress[0]
                ? 'This field is required'
                : validationErrors.addressLine1
              : undefined
          }
        />
      </div>

      <div className="form-section">
        <Input
          id={`${idPrefix}-address-line-2`}
          type="text"
          label="Address line 2"
          name="addressLine2"
          value={selectedAddress[1] || ''}
          onChange={(e) => handleAddressChange(1, e.target.value)}
        />
      </div>

      <div className="form-section">
        <Input
          id={`${idPrefix}-address-line-3`}
          type="text"
          label="Address line 3"
          name="addressLine3"
          value={selectedAddress[2] || ''}
          onChange={(e) => handleAddressChange(2, e.target.value)}
        />
      </div>

      <div className="form-section">
        <Input
          id={`${idPrefix}-address-line-4`}
          type="text"
          label="Address line 4"
          name="addressLine4"
          value={selectedAddress[3] || ''}
          onChange={(e) => handleAddressChange(3, e.target.value)}
        />
      </div>

      <div className="form-section">
        <Input
          id={`${idPrefix}-county`}
          type="text"
          label="County"
          name="county"
          value={selectedAddress[4] || ''}
          onChange={(e) => handleAddressChange(4, e.target.value)}
        />
      </div>

      <div className="form-section">
        <Input
          id={`${idPrefix}-city`}
          type="text"
          label="Town/City"
          name="city"
          value={selectedAddress[5] || ''}
          onChange={(e) => handleAddressChange(5, e.target.value)}
          required
          inlineError={
            showValidation ? (!selectedAddress[5] ? 'This field is required' : validationErrors.city) : undefined
          }
        />
      </div>
    </div>
  );
}
