import React, { ReactElement } from 'react';
import { Dropdown } from '../FormFields/FormFields';

interface AddressDropdownProps {
  addressOptions: Array<{
    label: string;
    value: number;
    rawLabel: string;
  }>;
  onAddressSelect: (address: string[]) => void;
  idPrefix: string;
}

export default function AddressDropdown({
  addressOptions,
  onAddressSelect,
  idPrefix
}: AddressDropdownProps): ReactElement {
  return (
    <div className="form-section">
      <Dropdown
        id={`${idPrefix}-address`}
        label="Select your address"
        name="address"
        options={addressOptions}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const address = addressOptions[Number(e.target.value)].rawLabel.split(',');
          onAddressSelect(address);
        }}
        initialOption="Select address"
      />
    </div>
  );
}
